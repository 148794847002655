import * as THREE from 'three';
import React, {
  useRef, useMemo, useEffect, useState
} from 'react';
import {
  extend, useThree, useLoader, useFrame
} from '@react-three/fiber';
import {  useDepthBuffer } from '@react-three/drei';
import { Water } from 'three-stdlib';
import { usePerformanceStore, useCanvasStore } from '@/store';

extend({ Water });

// Reference : https://codesandbox.io/s/water-shader-1b40u?file=/src/App.js:424-436

const Ocean = () => {

  const performanceTier = usePerformanceStore(state => state.performanceTier);
  const {
    sceneName
  }
    = useCanvasStore((state) =>
      ({
        sceneName : state.sceneName
      }));

  const [size, setSize] = useState(1024);

  const performanceTextureSize = {
    highRes : 1024,
    lowRes  : 256
  };

  useEffect(() => {
    performanceTier < 2 ? setSize(performanceTextureSize.lowRes) : setSize(performanceTextureSize.highRes);
  }, [performanceTier]);

  const OCEAN_SPEED = 0.5;

  const ref = useRef();
  const depthBuffer = useDepthBuffer({
    size   : 1024, // Size of the FBO, 256 by default
    frames : Infinity // How many frames it renders, Infinity by default
  });

  const gl = useThree((state) => state.gl);
  const waterNormals = useLoader(THREE.TextureLoader, '/textures/waternormals.jpg');
  waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping;
  const geom = useMemo(() => new THREE.PlaneGeometry(10000, 10000), []);
  const config = useMemo(
    () => ({

      textureWidth    : size,
      textureHeight   : size,
      clipBias        : 1.2,
      alpha           : 1,
      normalSampler   : depthBuffer,
      waterNormals,
      sunDirection    : new THREE.Vector3(0, 0, 1),
      sunColor        : 0xffffff,
      waterColor      : 0x000000,
      distortionScale : 8,
      fog             : true,
      format          : gl.encoding

    }),
    [waterNormals, depthBuffer, size, gl.encoding]
  );
  useFrame((state, delta) => (ref.current.material.uniforms.time.value -= delta * OCEAN_SPEED ));
  return (
    <water
      ref={ref}
      args={[geom, config]}
      rotation-x={-Math.PI / 2}
    />
  );
};

export default Ocean;
