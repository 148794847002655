import React, { useRef, useEffect, useState } from 'react';
import {
  useGLTF, useAnimations, Html, MeshReflectorMaterial
} from '@react-three/drei';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import gsap from 'gsap';
import { useFrame } from '@react-three/fiber';
import {
  EffectComposer, Outline, Selection, BrightnessContrast
} from '@react-three/postprocessing';
import Ocean from '@/components/canvas/ThreeScene/ThreeScene.ocean';
import { useSculpturesSliderStore, useCanvasStore, useAppStore } from '@/store';
import Button from '@/components/ui/Button/Button';
//import * as Styled from './ThreeScene.styled';
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

const propTypes = {

  routing : PropTypes.object
};
const defaultProps = {

  routing : {}
};

const cloudLeftOffset = 300;
const cloudMovementRange = 800;

export function AmphitheatreModel(props) {
  const {
    setCurrentSlide,
    sculpturesData
  } = useSculpturesSliderStore((state) => ({
    sculpturesData  : state.sculpturesData,
    setCurrentSlide : state.setCurrentSlide
  }));

  const { sceneName } = useCanvasStore(state => ({
    sceneName : state.sceneName
  }));

  const { setUserStartInteractive } = useAppStore(state => ({
    setUserStartInteractive : state.setUserStartInteractive
  }));

  const group = useRef();
  const cloud01 = useRef();
  const cloud02 = useRef();
  const posRef = useRef();
  // const treeLeaves = useRef();
  // const amphiFloor = useRef();
  const outlineFX = useRef();
  const entranceWalls = useRef();

  const hercules = useRef();

  const { nodes, materials, animations } = useGLTF('/models/3dScene/Scene2_V10-transformed.glb');

  const labelRefMedusa = useRef();
  const labelRefHercules = useRef();
  const labelRefOrpheus = useRef();
  const labelRefPan = useRef();
  const labelRefPhoenix = useRef();
  const labelRefSphinx = useRef();
  const labelRefAphrodite = useRef();
  const labelRefThreeGraces = useRef();

  const { actions } = useAnimations(animations, group); // eslint-disable-line no-eval

  const sculptures = useRef();
  const [hoveredSculpture, setHoveredSculpture] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const router = useRouter();

  useEffect(() => {

    cloud01.current.material.transparent = true;
    cloud02.current.material.transparent = true;

    cloud01.current.material.blending = THREE.AdditiveBlending;
    cloud02.current.material.blending = THREE.AdditiveBlending;

    cloud01.current.material.opacity = 1;
    cloud02.current.material.opacity = 1;

    cloud01.current.material.map.premultiplyAlpha = true;
    cloud02.current.material.map.premultiplyAlpha = true;

    // console.log(actions);

    // treeLeaves.current.material.color = new THREE.Color(0, 0, 0);
    // amphiFloor.current.material.map.repeat = new THREE.Vector2(0.8, 0.5);

    // entranceWalls.current.material.map.flipY = false;
    //entranceWalls.current.material.aoMap = null;
    // entranceWalls.current.material.map.repeat = new THREE.Vector2(0, 0);
    //console.log(nodes.Scene2_Amphitheater_WallEntrance_Geo.geometry);

    // Object.keys(materials).forEach((material) => {
    //   const materialCopy =  materials[material];
    //   const basicMaterial = new THREE.MeshBasicMaterial();

    //   basicMaterial.copy(materialCopy);

    //   materials[material] = basicMaterial;
    //   materialCopy.dispose();
    // });

    // console.log(nodes.Scene2_Amphitheater_WallEntrance_Geo);

  }, []);

  useFrame(({ clock }) => {
    if (sceneName === 'amphi-zoom-out' || sceneName === 'amphitheatre') {
      posRef.current = clock.elapsedTime * 2;
      cloud01.current.position.x = cloudLeftOffset - (posRef.current % cloudMovementRange);
      cloud02.current.position.x = (cloudLeftOffset + 200) - (posRef.current % cloudMovementRange);
    }
    outlineFX.current.edgeStrength = THREE.MathUtils.lerp(outlineFX.current.edgeStrength, 5, 0.005);

  });

  useEffect(() => {

    if (sceneName === 'amphi-zoom-out')  document.body.style.cursor = 'default';
    if (sceneName === 'amphitheatre') document.body.style.cursor = isHovered ? 'pointer' : 'auto';

    const refMap = {
      'medusa'       : labelRefMedusa,
      'hercules'     : labelRefHercules,
      'orpheus'      : labelRefOrpheus,
      'pan'          : labelRefPan,
      'phoenix'      : labelRefPhoenix,
      'sphinx'       : labelRefSphinx,
      'aphrodite'    : labelRefAphrodite,
      'three-graces' : labelRefThreeGraces
    };

    if (sculptures.current) {
      sculptures.current.children.forEach((sculpture) => {

        const s = sculpture.children[0];
        s.material.transparent = true;

        const isHoveredSculpture = s.name === hoveredSculpture;

        if (sceneName === 'amphitheatre'){
          if (isHoveredSculpture) {
            outlineFX.current.selection.add(sculpture.children[0]);

          } else {
            outlineFX.current.selection.delete(sculpture.children[0]);

          }

          gsap.to(s.material, {
            opacity  : isHoveredSculpture ? 0.7 : 0.99,
            duration : 0.5,
            ease     : 'power2.out',
            onUpdate : () => {
              gsap.set(refMap[s.name].current, {
                opacity : (0.99 - s.material.opacity) * 4
              });
            }
          });
        }

      });
    }
    if (hoveredSculpture) {
      setUserStartInteractive(true);
    }
  }, [hoveredSculpture, isHovered, sceneName]);

  const handleClick = (e) => {

    if (sceneName === 'amphitheatre') {
      let selected;
      sculpturesData.forEach((sculpture, index) => {
        if (e.object.name === sculpture.slug) {
          selected = index;
        };
      });
      router.push(`/sculptures`);
      setCurrentSlide(selected);
      e.stopPropagation();
      // setUserStartInteractive(true);
    }

  };

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
    >

      <Selection>
        <EffectComposer
          multisampling={8}
          disableNormalPass={true}
          autoClear={false}
        >
          <Outline
            ref={outlineFX}
            visibleEdgeColor="white"
            hiddenEdgeColor="white"
            blur
            edgeStrength={0}
            dithering

          />
          <BrightnessContrast brightness={-0.1} contrast={0.25} />
        </EffectComposer>

        <group name="Scene">

          <mesh  position={[-12.25, -0.55, 69.35]} rotation={[-Math.PI * 0.5, 0, 0]}>
            <planeBufferGeometry args={[1000, 1000]}  />
            <MeshReflectorMaterial
              mirror={1}
              resolution={512}
              mixStrength={0.5}
              mixContrast={1}
              blur={[10, 10]}
            />
          </mesh>

          {/* <mesh
            name="Scene2_Ocean_Ocean_Geo"
            geometry={nodes.Scene2_Ocean_Ocean_Geo.geometry}
            material={materials.Ocean}
            position={[-12.25, -0.55, 69.35]}
          /> */}
          <mesh
            name="Scene2_Ocean_Shore_FX"
            geometry={nodes.Scene2_Ocean_Shore_FX.geometry}
            material={materials.Shore}
            position={[0.32, -0.34, 26.19]}
          />
          <mesh
            name="Scene2_Debris_Geo"
            geometry={nodes.Scene2_Debris_Geo.geometry}
            material={materials.rockslate}
            position={[11.55, 0.1, 11.45]}
            rotation={[-Math.PI, 0, 0]}
            scale={0.4}
          />
          <mesh
            name="Scene2_Gravel_Geo"
            geometry={nodes.Scene2_Gravel_Geo.geometry}
            material={materials.slate_gravel}
            position={[-10.36, 0.2, 5.37]}
            rotation={[-0.18, -0.94, -0.22]}
            scale={1.39}
          />
          <mesh
            name="Scene2_OutsideShore_Shore_Geo"
            geometry={nodes.Scene2_OutsideShore_Shore_Geo.geometry}
            material={materials.Soil}
            position={[-8.06, 0.13, 13.59]}
          />
          <mesh
            name="Scene2_Shore_Pots_Geo"
            geometry={nodes.Scene2_Shore_Pots_Geo.geometry}
            material={materials.Pots}
            position={[-3.88, -0.03, 12.51]}
            rotation={[Math.PI / 2, 0, -0.12]}
            scale={0.03}
          />
          <mesh
            name="Scene2_Shore_Shore_Geo"
            geometry={nodes.Scene2_Shore_Shore_Geo.geometry}
            material={materials.Beach}
            position={[-8.06, 0.13, 13.59]}
          />
          <mesh
            name="Scene2_Amphitheater_FloorTrim_Geo"
            geometry={nodes.Scene2_Amphitheater_FloorTrim_Geo.geometry}
            material={materials.Prop_Set_01}
            position={[0.27, 0.2, -7.96]}
          />
          <mesh
            name="Scene2_Amphitheater_WallEntrance_Geo"
            ref={entranceWalls}
            geometry={nodes.Scene2_Amphitheater_WallEntrance_Geo.geometry}
            material={materials.Prop_Set_01}
            position={[11.53, 5.7, 0.49]}
            rotation={[0, 0.02, 0]}
          />
          <mesh
            name="Scene2_Amphitheater_Floor_Geo"
            geometry={nodes.Scene2_Amphitheater_Floor_Geo.geometry}
            material={materials.Tiles}
            position={[0.27, 0.2, -7.96]}
          />
          <mesh
            name="Scene2_Skybox_Cliffs_Geo"
            geometry={nodes.Scene2_Skybox_Cliffs_Geo.geometry}
            material={materials.rock_cliff}
            position={[-69.94, 18.45, -143.97]}
            rotation={[-0.81, 1.42, 1.02]}
            scale={4.37}
          />
          <mesh
            name="Scene2_Skybox_Clouds2_Geo"
            ref={cloud02}
            geometry={nodes.Scene2_Skybox_Clouds2_Geo.geometry}
            material={materials.Clouds_02}
            position={[38.25, 55.08, -193.47]}
            scale={1.18}
          />
          <mesh
            name="Scene2_Skybox_Clouds1_Geo"
            ref={cloud01}
            geometry={nodes.Scene2_Skybox_Clouds1_Geo.geometry}
            material={materials.Clouds_01}
            position={[-50.75, 61.88, -186.97]}
            scale={1.55}
          />
          <mesh
            name="Scene2_Skybox_Clouds3_Geo"
            geometry={nodes.Scene2_Skybox_Clouds3_Geo.geometry}
            material={materials.Clouds_01}
            position={[-282.75, 61.88, -186.97]}
            scale={1.55}
          />
          <group
            name="Scene2_Skybox_Trees_Geo"
            position={[-38.71, 4.03, -37.23]}
            rotation={[0.28, -0.01, -0.08]}
          >
            <mesh
              name="Scene2_Skybox_Trees_Geo_1"
              castShadow
              receiveShadow
              geometry={nodes.Scene2_Skybox_Trees_Geo_1.geometry}
              material={materials.acacia_bark}
            />
            <mesh
              name="Scene2_Skybox_Trees_Geo_2"
              castShadow
              receiveShadow
              geometry={nodes.Scene2_Skybox_Trees_Geo_2.geometry}
              material={materials.acacia_leaves}
            />
          </group>
          <mesh
            name="Scene2_Amphitheater_Wall_Geo"
            geometry={nodes.Scene2_Amphitheater_Wall_Geo.geometry}
            material={materials.Stone_Wall}
            position={[0.27, 2.85, -18.56]}
          />
          <mesh
            name="Scene2_Skybox_Hills_Geo"
            geometry={nodes.Scene2_Skybox_Hills_Geo.geometry}
            material={materials.Soil_Skybox}
            position={[10.53, 4.25, -57.13]}
          />
          <group
            ref={sculptures}
            onPointerOver={(e) => (e.stopPropagation(), setIsHovered(true),  setHoveredSculpture(e.object.name))}
            onPointerOut={(e) => (e.stopPropagation(), setIsHovered(false), setHoveredSculpture(''))}
            onClick={handleClick}
          >

            <group
              position={[-9.16, 3.17, -12.17]}
              rotation={[0, 1.04, 0]}
              scale={1.45}
            >
              <mesh
                name="medusa"
                geometry={nodes.Scene2_Amphitheater_Medusa_5k_Geo.geometry}
                material={materials.Medusa_5k}
              />
              <Html
                ref={labelRefMedusa}
                wrapperClass="sticky"
                position={[0, 1.5, 0]}
                center
                style={{ opacity : 0, pointerEvents : 'none' }}
              >
                <Button isLabel={true} hasDiamonds={false} >
                  Medusa
                </Button>
              </Html>
            </group>
            <group
              position={[0.29, 1.12, -15.43]}
              scale={2.12}
            >
              <mesh
                name="hercules"
                ref={hercules}
                geometry={nodes.Scene2_Amphitheater_Hercules_5k_Geo.geometry}
                material={materials.Hercules_5k}
              />

              <Html
                ref={labelRefHercules}
                wrapperClass="sticky"
                position={[0, 1.35, 0]}
                center
                style={{ opacity : 0, pointerEvents : 'none' }}
              >
                <Button isLabel={true} hasDiamonds={false} >
                  Hercules
                </Button>
              </Html>
            </group>
            <group
              position={[-5.15, 3.19, -16.75]}
              rotation={[-Math.PI, 1.04, -Math.PI]}
              scale={1.31}
            >
              <mesh
                name="orpheus"
                geometry={nodes.Scene2_Amphitheater_Orpheus_5k_Geo.geometry}
                material={materials.Orpheus_5k}
              />
              <Html
                ref={labelRefOrpheus}
                wrapperClass="sticky"
                position={[0, 1.8, 0]}
                center
                style={{ opacity : 0, pointerEvents : 'none' }}
              >
                <Button isLabel={true} hasDiamonds={false} >
                  Orpheus
                </Button>
              </Html>
            </group>

            <group
              position={[-10.57, 5.31, -15.79]}
              rotation={[0, 0.91, 0]}
              scale={1.53}
            >
              <mesh
                name="pan"
                geometry={nodes.Scene2_Amphitheater_Pan_5k_Geo.geometry}
                material={materials.Pan_5k}
              />
              <Html
                ref={labelRefPan}
                wrapperClass="sticky"
                position={[0, 1.2, 0]}
                center
                style={{ opacity : 0, pointerEvents : 'none' }}
              >
                <Button isLabel={true} hasDiamonds={false} >
                  Pan
                </Button>
              </Html>
            </group>
            <group
              position={[10.97, 5.36, -16.53]}
              rotation={[-0.02, -1.03, -0.01]}
              scale={2.23}
            >
              <mesh
                name="phoenix"
                geometry={nodes.Scene2_Amphitheater_Pheonix_5k_Geo.geometry}
                material={materials.Pheonix_5k}
              />
              <Html
                ref={labelRefPhoenix}
                wrapperClass="sticky"
                position={[0, 1.2, 0]}
                center
                style={{ opacity : 0, pointerEvents : 'none' }}
              >
                <Button isLabel={true} hasDiamonds={false} >
                  Phoenix
                </Button>
              </Html>
            </group>
            <group
              position={[0.31, 5.34, -21.62]}
              scale={1.4}
            >
              <mesh
                name="sphinx"
                geometry={nodes.Scene2_Amphitheater_Sphinx_5k_Geo.geometry}
                material={materials.Sphinx_5k}
              />
              <Html
                ref={labelRefSphinx}
                wrapperClass="sticky"
                position={[0, 1.7, 0]}
                center
                style={{ opacity : 0, pointerEvents : 'none' }}
              >
                <Button isLabel={true} hasDiamonds={false} >
                  Sphinx
                </Button>
              </Html>
            </group>
            <group
              position={[4.91, 3.19, -17.61]}
              rotation={[0, -0.37, 0]}
              scale={1.33}
            >
              <mesh
                name="aphrodite"
                geometry={nodes.Scene2_Amphitheater_Aphrodite_5k_Geo.geometry}
                material={materials.Aphrodite_5k}
              />
              <Html
                ref={labelRefAphrodite}
                wrapperClass="sticky"
                position={[0, 1.8, 0]}
                center
                style={{ opacity : 0, pointerEvents : 'none' }}
              >
                <Button isLabel={true} hasDiamonds={false} >
                  Aphrodite
                </Button>
              </Html>
            </group>
            <group
              position={[9.68, 3.2, -12.35]}
              rotation={[0, -1.25, 0]}
              scale={1.56}
            >
              <mesh
                name="three-graces"
                geometry={nodes.Scene2_Amphitheater_Three_Graces_5k_Geo.geometry}
                material={materials.Three_Graces_5k}
              />
              <Html
                ref={labelRefThreeGraces}
                wrapperClass="sticky"
                position={[0, 1.5, 0]}
                center
                style={{ opacity : 0, pointerEvents : 'none' }}
              >
                <Button isLabel={true} hasDiamonds={false} >
                  Three Graces
                </Button>
              </Html>
            </group>

          </group>

          <mesh
            name="Scene2_Amphitheater_Amphitheater_Geo"
            geometry={nodes.Scene2_Amphitheater_Amphitheater_Geo.geometry}
            material={materials.Amphitheater}
            position={[0.27, 2.81, -20.46]}
          />
        </group>
      </Selection>
    </group>
  );
}
AmphitheatreModel.propTypes = propTypes;
AmphitheatreModel.defaultProps = defaultProps;

export function BoatAndBackgroundModel(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('/models/3dScene/Scene1_V9-transformed.glb');
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    actions?.Sailing.play();

  });

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      rotation={[0, Math.PI, 0]}
    >
      <group name="Scene">
        <group
          name="Armature boat"
          position={[-41, -1.52, 445]}
          rotation={[0, Math.PI * -0.5, 0]}
        >
          <primitive object={nodes.Main} />
          <primitive object={nodes.neutral_bone} />
          <primitive object={nodes.neutral_bone_1} />
          {/* <mesh
            name="Scene1_Ocean_Ropes_Geo"
            geometry={nodes.Scene1_Ocean_Ropes_Geo.geometry}
            material={materials.Ship_Rope}
            position={[0.19, 14.64, -1.77]}
            rotation={[0, 1.57, 0]}
            scale={1.01}
          /> */}
          <mesh
            name="Scene1_Ocean_DeckParts_Geo"
            geometry={nodes.Scene1_Ocean_DeckParts_Geo.geometry}
            material={materials.Ship_Wood}
            position={[4.84, 5.99, 3.12]}
            rotation={[0, 1.57, 0]}
            scale={1.01}
          />
          <mesh

            name="Scene1_Ocean_RowsBack_Geo"
            geometry={nodes.Scene1_Ocean_RowsBack_Geo.geometry}
            material={materials.Ship_Wood}
            position={[10.55, -2.65, -11.05]}
            rotation={[0, 1.57, 0]}
            scale={1.01}
            frustumCulled={false}
          />

          <skinnedMesh
            name="Scene1_Ocean_Rows_Geo"
            geometry={nodes.Scene1_Ocean_Rows_Geo.geometry}
            material={materials.Ship_Wood}
            skeleton={nodes.Scene1_Ocean_Rows_Geo.skeleton}
            frustumCulled={false}
          />

          <mesh
            name="Scene1_Ocean_Deck_Geo"
            geometry={nodes.Scene1_Ocean_Deck_Geo.geometry}
            material={materials.Ship_Planks}
            position={[-4.45, 5.27, 4.84]}
            rotation={[0, 1.57, 0]}
            scale={1.01}
          />
          <skinnedMesh

            name="Scene1_Ocean_Sails_Geo"
            geometry={nodes.Scene1_Ocean_Sails_Geo.geometry}
            material={materials.Ship_Sails}
            skeleton={nodes.Scene1_Ocean_Sails_Geo.skeleton}
            frustumCulled={false}
          />
          <mesh
            name="Scene1_Ocean_Hull_Geo"
            geometry={nodes.Scene1_Ocean_Hull_Geo.geometry}
            material={materials.Ship_Hull}
            rotation={[0, 1.57, 0]}
            scale={1.01}
          />
        </group>
        {/* <mesh
        ref={ocean}
          name="Scene1_Ocean_Ocean_Geo"
          geometry={nodes.Scene1_Ocean_Ocean_Geo.geometry}
          material={materials.Ocean}
          position={[-58.95, -0.32, 155.56]}
        /> */}
        <group position={[-58.95, -0.32, 155.56]} >

          <Ocean />
        </group>
        <mesh
          name="Scene1_Skybox_Hills_Geo"
          geometry={nodes.Scene1_Skybox_Hills_Geo.geometry}
          material={materials.Soil_Skybox}
          position={[10.53, 0.97, -57.13]}
        >
          <meshStandardMaterial color={'white'} />

        </mesh>

        {/* <mesh
          name="Scene1_Ocean_OceanBake_Geo"
          geometry={nodes.Scene1_Ocean_OceanBake_Geo.geometry}
          material={materials.OceanBake}
          position={[240, -0.32, 175]}
        /> */}

      </group>
    </group>
  );
}

export const preloadModels = () => {
  useGLTF.preload('/models/3dScene/Scene1_V9-transformed.glb');
  useGLTF.preload('/models/3dScene/Scene2_V10-transformed.glb');
};
